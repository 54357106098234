<template>
    <v-container fill-height bottom-gradient fluid class="ma-0 pa-0 font-raleway">
        <v-row dense>
            <v-col cols="12">
                <v-card rounded="lg" outlined class="simple-border pa-1 align-content-end">
                    <v-toolbar elevation="0" tile flat>
                        <v-select
                            dense
                            v-model="selectedProjectId"
                            :items="projects"
                            item-text="project.name"
                            item-value="project_id"
                            item-color="primary"
                            label="Proyecto"
                            required
                            class="ma-2 mt-7"
                            @input="applyFilter($event)">
                        </v-select>
                    </v-toolbar>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-container align-center text-xs-center>
                <v-layout row wrap>
                    <v-flex md4 v-for="(item, index) in cardData" :key="index" class="pa-1 lg5-custom">
                        <v-card flat rounded="lg" outlined class="simple-border">
                            <v-card-text>
                                <v-row no-gutters justify="space-between">
                                    <v-col cols="12">
                                        <div class="d-flex flex-column h-100">
                                            <div class="text-muted text-subtitle-2 font-weight-regular">
                                                {{ item.title }}
                                            </div>
                                            <v-divider v-if="true"/>
                                            <div class="mt-auto">
                                                <countTo class="text-subtitle-1 font-weight-bold mx-0"
                                                         :startVal='startVal'
                                                         :endVal='item.value'
                                                         :duration='1000'
                                                         :prefix="item.is_currency ? '$' : ''"
                                                         :suffix="item.is_currency ? ' mxn' : ' %'"
                                                         :decimals="2"></countTo>
                                            </div>
                                        </div>
                                    </v-col>
                                    <!--v-col cols="12" v-if="false">
                                        <div class="temp-class">
                                            <VueApexCharts
                                                ref="chartProjectsRadial"
                                                class="apex-charts"
                                                type="radialBar"
                                                height="200"
                                                :options="radialChartOptions[index]"
                                                :series="item.rate"/>
                                        </div>
                                    </v-col-->
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-card flat rounded="lg" min-height="490" outlined class="pa-2 simple-border">
                    <v-container fluid class="container--fluid fill-height">
                        <v-row class="align-content-start">
                            <v-col cols="12" md="12">
                              <div class="ma-2 pt-2">
                                <v-row v-if="false">
                                  <v-card flat class="ma-0 pa-0 align-center justify-start" style="width:100%;">
                                    <v-select
                                        dense
                                        v-model="selectedProjectId"
                                        :items="projects"
                                        item-text="project.name"
                                        item-value="project_id"
                                        item-color="primary"
                                        label="Proyecto"
                                        required
                                        class="ma-2"
                                        @input="applyFilter($event)">
                                    </v-select>
                                  </v-card>
                                </v-row>
                                <v-row v-if="eventsProject.length > 0">
                                  <div class="container--fluid fill-height" style="width:100%;">
                                    <VueApexCharts

                                        height="450"
                                        type="line"
                                        :options="chartProjectOptions"
                                        :series="seriesProject"/>
                                  </div>
                                </v-row>
                                <v-row justify="center" v-else>
                                  <v-col cols="12">
                                    <div class="container--fluid fill-height" style="width:100%;">
                                                  <span class="text-h5 text-center">
                                                      Este proyecto no contiene eventos asociados.
                                                  </span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                            <v-col cols="12" md="12">
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card flat rounded="lg" min-height="100" outlined class="pa-2 simple-border">
            <v-row class="align-content-start">
              <v-col cols="12" md="5">
                <v-list two-line>
                  <v-subheader class="font-weight-bold">
                                        <span v-if="dataDetailReportProjectInvestor !== null">
                                            {{ dataDetailReportProjectInvestor.investor_name }}
                                        </span>
                  </v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="success lighten-4" rounded="lg" size="35">
                      <v-icon>mdi-chart-line</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Monto de Inversi&oacute;n
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <countTo v-if="dataDetailReportProjectInvestor !== null"
                                 :startVal='startVal'
                                 :endVal='dataDetailReportProjectInvestor.investment_amount'
                                 :duration='1000'
                                 prefix="$" suffix=" mxn" :decimals="2"
                                 class="text-subtitle-1 font-weight-bold">
                        </countTo>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider inset/>
                  <v-list-item>
                    <v-list-item-avatar color="blue lighten-4" rounded="lg" size="35">
                      <v-icon>mdi-access-point</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Inversi&oacute;n en Puntos
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <countTo v-if="dataDetailReportProjectInvestor !== null"
                                 :startVal='startVal'
                                 :endVal='dataDetailReportProjectInvestor.investment_points'
                                 :duration='1000'
                                 prefix="" suffix="" :decimals="2"
                                 class="text-subtitle-1 font-weight-bold">
                        </countTo>
                        <span class="font-weight-light text-caption"> puntos</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="6" class="mt-8">
                <v-expansion-panels focusable accordion class="pa-1"
                                    style="max-height:250px; overflow-y: auto">
                  <v-expansion-panel
                      v-for="(item,i) in eventsProject"
                      :key="i">
                    <v-expansion-panel-header :hide-actions="true">
                      {{ item.event_name }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="mx-0 px-0" style="font-size:8pt;">
                      <table cellpadding="1" cellspacing="1"
                             class="pa-0 ma-0 text-left justify-start transparent">
                        <tr>
                          <th>Inversi&oacute;n Total</th>
                          <td>{{ item.investment_amount_event | toCurrency }} mxn</td>
                        </tr>
                        <tr style="color:#4989f6;">
                          <th>Crecimiento Capital</th>
                          <td>{{ item.capital_grow_event | toCurrency }} mxn</td>
                        </tr>
                        <tr style="color:#8000FF;">
                          <th>Ingresos</th>
                          <td>{{ item.investment_income_event | toCurrency }} mxn</td>
                        </tr>
                        <tr style="color:#6db769;">
                          <th>Utilidad o P&eacute;rdida</th>
                          <td>{{ item.utility_event | toCurrency }} mxn</td>
                        </tr>
                      </table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
        <v-row dense v-if="events.length > 0">
            <v-col cols="12">
                <v-card rounded="lg" outlined class="simple-border pa-1">
                    <span class="text-h5 ml-2">
                        Eventos
                    </span>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense v-if="events.length > 0">
            <v-container align-center text-xs-center>
                <v-layout row wrap>
                    <v-flex md3 v-for="(item, index) in cardDataEvent" :key="index" class="pa-1">
                        <v-card flat rounded="lg" outlined class="simple-border">
                            <v-card-text>
                                <v-row no-gutters justify="space-between">
                                    <v-col cols="12">
                                        <div class="d-flex flex-column h-100">
                                            <div class="text-muted text-subtitle-2 font-weight-regular">
                                                {{ item.title }}
                                            </div>
                                            <v-divider v-if="true"/>
                                            <div class="mt-auto">
                                                <countTo class="text-subtitle-1 font-weight-bold mx-0"
                                                         :startVal='startVal'
                                                         :endVal='item.value'
                                                         :duration='1000'
                                                         prefix="$"
                                                         suffix="mxn"
                                                         :decimals="2"></countTo>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="temp-class">
                                            <!--VueApexCharts
                                                class="apex-charts"
                                                type="radialBar"
                                                height="200"
                                                :options="radialChartOptions"
                                                :series="item.rate"/-->
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-row>
        <v-row dense v-if="events.length > 0">
            <v-col cols="12">
                <v-card flat rounded="lg" min-height="490" outlined class="pa-2 simple-border">
                    <v-container fluid class="container--fluid fill-height mt-5">
                        <v-row class="align-content-start">
                            <v-col cols="12" md="3">
                                <v-subheader class="font-weight-bold">
                                        <span v-if="dataReportEventInvestor !== null">
                                            {{ dataReportEventInvestor.investor_name }}
                                        </span>
                                </v-subheader>
                                <v-expansion-panels focusable accordion class="pa-1"
                                                    style="max-height:250px; overflow-y: auto">
                                    <v-expansion-panel
                                        v-for="(item,i) in events"
                                        :key="i">
                                        <v-expansion-panel-header :hide-actions="true"
                                                                  class="py-0 my-0 text-body-2">
                                            {{ item.event_name }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="text-caption mx-0 px-0">
                                            <table cellpadding="0" cellspacing="0"
                                                   class="pa-0 ma-0 text-left justify-start transparent">
                                                <tr>
                                                    <th>Inversi&oacute;n Total</th>
                                                    <td>{{ item.investment_amount_event | toCurrency }} mxn</td>
                                                </tr>
                                                <tr>
                                                    <th>Ingresos</th>
                                                    <td>{{ item.investment_income_event | toCurrency }} mxn</td>
                                                </tr>
                                                <tr>
                                                    <th>Gastos</th>
                                                    <td>{{ item.expense_event | toCurrency }} mxn</td>
                                                </tr>
                                                <tr>
                                                    <th>Utilidad o P&eacute;rdida</th>
                                                    <td>{{ item.utility_event | toCurrency }} mxn</td>
                                                </tr>
                                            </table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="ma-2 pt-2">
                                    <v-row>
                                        <div class="container--fluid fill-height" style="width:100%;">
                                            <VueApexCharts
                                                ref="chartEvents"
                                                height="400" 
                                                type="line"
                                                :options="chartEventOptions"
                                                :series="seriesEvent"/>
                                        </div>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import investorService from '@/providers/InvestorService';
    import countTo from 'vue-count-to';
    import VueApexCharts from "vue-apexcharts";
    // import ProjectChartSettings from "./ProjectChartSettings";
    // import EventChartSettings from "./EventChartSettings";

    // const SI_SYMBOL = ["", " K", " MDP", " G", " T", " P", " E"];

    /*function abbreviateNumber(number){

        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if(tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }*/

    function formatToUnits(number, precision) {
        const abbrev = ['', ' K', ' M', ' B', ' T'];
        const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
        const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
        const suffix = abbrev[order];

        return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
    }

    export default {
        props: {
            height: {
                type: String,
                default: "400px",
            },
            color: {
                type: String,
                default: "orange",
            },
            containerID: {
                type: String,
                default: "map",
            }
        },
        name: "InvestorDashboardComponent",
        components: { countTo, VueApexCharts },
        data: () => ({
            startVal: 0,
            endVal: 2800000,
            dashboardData: null,
            cardData: [],
            cardDataEvent: [],
            investor: null,
            projects: [],
            selectedProjectId: null,
            selectedInvestorId: null,
            dataReportProjectInvestor: null,
            dataDetailReportProjectInvestor: null,
            dataReportEventInvestor: null,
            events: [],
            eventsProject : [],
            // projectChartSettings: ProjectChartSettings,
            // eventChartSettings: EventChartSettings,
            radialChartOptions: [
                {
                    colors: ['#3ec23e'],
                    chart: {
                        height: '250',
                        width: "100%",
                        type: 'radialBar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    labels: ['Inversión'],
                },
                {
                    colors: ['#5ae15a'],
                    chart: {
                        height: '250',
                        width: "100%",
                        type: 'radialBar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    labels: ['Ingresos'],
                },
                {
                    colors: ['#2e912e'],
                    chart: {
                        height: '250',
                        width: "100%",
                        type: 'radialBar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    labels: ['Gastos'],
                },
                {
                    colors: ['#6bf16b'],
                    chart: {
                        height: '250',
                        width: "100%",
                        type: 'radialBar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    labels: ['Utilidad'],
                },
                {
                    colors: ['#088308'],
                    chart: {
                        height: '250',
                        width: "100%",
                        type: 'radialBar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 3,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    labels: ['Variación'],
                },
            ],
            seriesProject: [
                {
                    name: 'Ingresos',
                    type: 'line',
                    data: []
                },{
                    name: 'Utilidad',
                    type: 'line',
                    data: []
                }
            ],			
			chartProjectOptions: {
                chart: { 
                    id: 'chart-projects',
                    height: 350,
                    width: '100%',
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                stroke: {
                    width: [1, 4, 4, 4]
                },
                markers: {
                    size: 5,
                    strokeColors: "transparent",
                },
                grid: {
                    borderColor: "rgba(0, 0, 0, .1)",
                    show: true,
                    enabled: true,
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                // colors: ['#387df3', '#de373d', '#8000FF', '#aade87'],
                colors: [
                    '#4989f6',
                    //'#8000FF',
                    '#079a67',
                    '#88c75e'
                ],
                title: {
                    text: '',
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        console.log('series:', series);
                        console.log('seriesIndex:', seriesIndex);
                        let data = w.globals.initialSeries[0].data[dataPointIndex];
                        return '<v-card elevation="2" outlined class="ma-3 caption transparent text-left justify-start">' +
                            '<table cellpadding="3" cellspacing="3" class="transparent">' +
                                '<tr class="text-left">' +
                                    '<th>Evento: </th>' +
                                    '<th>' +
                                        '<v-card-title class="grey--text text--darken-3">'+data.full_label+'</v-card-title>' +
                                    '</th>' +
                                '</tr>' +
                            '<tr class="card-header" style="color: #4989f6;">' +
                            '<th>Crecimiento: </th>' +
                            '<td>' +
                                '<v-card-title class="grey--text text--darken-3">'+
                                    '<span style="color: #4989f6;">' +
                                        '$ '+formatToUnits(data.y, 2)+'</v-card-title>' +
                                    '</span>'+
                                '</td>' +
                            '</tr>' +
                                '<tr class="card-header" style="color: #8000FF;">' +
                                    '<th>Ingresos: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                        '    <span style="color: #8000FF;">'+
                                            '$ '+formatToUnits(data.income, 2)+
                                        '   </span> '+
                                        ' </v-card-title>' +
                                    '</td>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #079a67;">' +
                                    '<th>Utilidad: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                            '<span style="color: #079a67;">' +
                                                '$ '+formatToUnits(data.utility, 2)+
                                            '</span>'+
                                        '</v-card-title> ' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                            '</v-card>';
                    }
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [2],
                    formatter: function (value) {
                        // return formatToUnits(value, 0);
                        return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                    }
                },
                labels: [],
                legend: {
                    position: 'top',
                },
                xaxis: {
                    type: 'string',
                    lines: {
                        show: true,
                    },
                    categories: [],
                    labels: {
                        show: true,
                        style: {
                            cssClass: "grey--text text--lighten-2 fill-color",
                        }
                    },
                },
                yaxis: [
                    {
                        seriesName: "Eventos",
                        show: true,
                        type: "numeric",
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#dedee0'
                        },
                        labels: {
                            style: {
                                colors: '#4989f6',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Eventos',
                            style: {
                                color: '#4989f6',
                            }
                        },
                        tooltip: {
                            enabled: false
                        },
                    },{
                        seriesName: "Ingresos",
                        show: true,
                        type: "numeric",
                        opposite: true,
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#05754e'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Utilidad o Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    },{
                        seriesName: "Ingresos",
                        type: "numeric",
                        show: false,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Utilidad/Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    },{
                        seriesName: "Lineal",
                        logarithmic: false,
                        tickAmount: 4,
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false
                        },
                        labels: {
                            show:false,
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                ]
            },
            seriesEvent: [
            {
                name: 'Crecimiento',
                type: 'column',
                data: []
            },{
                name: 'Ingresos',
                type: 'line',
                data: []
            },{
                name: 'Utilidad',
                type: 'line',
                data: []
            }
            ],
            chartEventOptions: {
                chart: {
                    id: 'chart-events',
                    height: 400,
                    width: '100%',
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                stroke: {
                    width: [1, 4, 4]
                },
                markers: {
                    size: 5,
                    strokeColors: "transparent",
                },
                grid: {
                    borderColor: "rgba(0, 0, 0, .1)",
                    show: true,
                    enabled: true,
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                colors: [
                    '#4989f6',
                    //'#8000FF',
                    '#079a67',
                    '#88c75e'
                ],
                title: {
                    text: '',
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        console.log(series);
                        console.log(seriesIndex);
                        let data = w.globals.initialSeries[0].data[dataPointIndex];
                        return '<v-card elevation="2" outlined class="ma-3 caption transparent text-left justify-start">' +
                            '<table cellpadding="3" cellspacing="3" class="transparent">' +
                                '<tr class="text-left">' +
                                    '<th>Evento: </th>' +
                                    '<th>' +
                                        '<v-card-title class="grey--text text--darken-3">'+data.full_label+'</v-card-title>' +
                                    '</th>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #4989f6;">' +
                                    '<th>Crecimiento: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                            '<span style="color: #4989f6;">' +
                                                '$ '+formatToUnits(data.y, 2)+
                                            '</span>'+
                                          '</v-card-title>' +
                                    '</td>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #8000FF;">' +
                                    '<th>Ingresos: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                        '    <span style="color: #8000FF;">'+
                                            '$ '+formatToUnits(data.income, 2)+
                                        '   </span> '+
                                        ' </v-card-title>' +
                                    '</td>' +
                                '</tr>' +
                                '<tr class="card-header" style="color: #079a67;">' +
                                    '<th>Utilidad: </th>' +
                                    '<td>' +
                                        '<v-card-title class="grey--text text--darken-3">'+
                                            '<span style="color: #079a67;">' +
                                                '$ '+formatToUnits(data.utility, 2)+
                                            '</span>'+
                                        '</v-card-title> ' +
                                    '</td>' +
                                '</tr>' +
                            '</table>' +
                        '</v-card>';
                    }
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1],
                    formatter: function (value) {
                        return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                    }
                },
                labels: ['Crecimiento', 'Ingresos', 'Utilidad/Pérdida'],
                xaxis: {
                    type: 'string',
                    lines: {
                        show: true,
                    },
                    categories: [],
                    labels: {
                        style: {
                            cssClass: "grey--text text--lighten-2 fill-color",
                        }
                    },
                },
                yaxis: [
                    {
                        seriesName: "Crecimiento",
                        min: 0,
                        show: true,
                        type: "numeric",
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#dedee0'
                        },
                        labels: {
                            style: {
                                colors: '#4989f6',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Crecimiento',
                            style: {
                                color: '#4989f6',
                            }
                        },
                        tooltip: {
                            enabled: false
                        },
                    },{
                        seriesName: "Ingresos",
                        min: 0,
                        show: true,
                        type: "numeric",
                        opposite: true,
                        lines: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#05754e'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Utilidad o Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    },{
                        seriesName: "Ingresos",
                        min: 0,
                        type: "numeric",
                        show: false,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#00E396'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: '#05754e',
                            },
                            formatter: function (value) {
                                return '$ '+formatToUnits(value, 2);
                                // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                            }
                        },
                        title: {
                            text: 'Utilidad/Pérdida',
                            style: {
                                color: '#05754e',
                            }
                        },
                    }
                ]
            }
        }),
        filters: {
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            toTwoDecimal (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    useGrouping: false,
                    maximumSignificantDigits: 2,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        computed: {
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            }
        },
        methods: {
            applyFilter(event) {
                this.selectedProjectId = event;
                this.getDetailReport(this.selectedProjectId, this.selectedInvestorId);
            },
            getDetailReport(project_id, investor_id) {
                investorService.getReportDetailProjectsInvestor(project_id, investor_id).then(record => {
                    if (record.success) {
                        this.dataDetailReportProjectInvestor = record.data;
                        this.cardData[0].rate =
                            [Math.floor(this.dataDetailReportProjectInvestor.investment_amount*100/this.cardData[0].value)];
                        this.cardData[1].rate =
                            [Math.floor(this.dataDetailReportProjectInvestor.investment_income*100/this.cardData[1].value)];
                        this.cardData[2].rate =
                            [Math.floor(this.dataDetailReportProjectInvestor.expense*100/this.cardData[2].value)];
                        this.cardData[3].rate =
                            [Math.floor(this.dataDetailReportProjectInvestor.utility*100/this.cardData[3].value)];
                        this.cardData[4].rate =
                            [Math.floor(this.cardData[4].value*100)];

                        this.chartProjectOptions.title.text = this.dataDetailReportProjectInvestor.project_name;
                        this.seriesProject[0].data = [];
                        this.seriesProject[1].data = [];
                        this.chartProjectOptions.labels = [];
                        let growValues = [];
                        let incomeValues = [];
                        let revueneValues = [];
                        let expensesValue = [];
                        let linealValues = [];
                        let labels = [];
                        this.eventsProject = this.dataDetailReportProjectInvestor.events;
                        let idx = 0;
                        // console.log('eventsProject: ', this.eventsProject);
                        this.eventsProject.forEach(data => {
                            this.eventsProject[idx].color =
                                this.chartProjectOptions.colors[idx];
                            idx++;
                            let growValue = {
                                // x : data.event_name.split(' ').length>3 ? data.event_name.split(" ") : data.event_name,
                                x : data.event_name.split(" ")[0],
                                y : data.capital_grow_event,
                                full_label: data.event_name,
                                income: data.investment_income_event,
                                expense: data.expense_event,
                                utility: data.utility_event,
                            };
                            growValues.push(growValue);
                            let incomeValue = {
                                x : data.event_index_name,
                                y : data.investment_income_event,
                                full_label: data.event_name
                            };
                            incomeValues.push(incomeValue);
                            let expenseValue = {
                                x : data.event_index_name,
                                y : data.expense_event,
                                full_label: data.event_name
                            }
                            expensesValue.push(expenseValue);
                            let revenueValue = {
                                x : data.event_index_name,
                                y : data.utility_event,
                                full_label: data.event_name
                            };
                            revueneValues.push(revenueValue);
                            let linealValue = {
                                x : '',
                                y : data.investment_amount_event,
                                full_label: data.event_name
                            };
                            linealValues.push(linealValue);
                            labels.push(data.event_index_name);
                        });
                        this.seriesProject = [
                            {
                                name: 'Eventos',
                                type: 'column',
                                data: growValues
                            },/*{
                                name: 'Ingresos',
                                type: 'line',
                                data: incomeValues
                            },*/{
                                name: 'Utilidad/Pérdida',
                                type: 'line',
                                data: revueneValues
                            }
                        ];
                        this.chartProjectOptions.xaxis.categories = labels;
                        // this.$refs.chartProjectsRadial.refresh();
                        // this.$refs.chartProjects.refresh();
                    } else {
                        this.dataDetailReportProjectInvestor = null;
                        this.eventsProject = [];
                    }
                }).catch(err => {
                    console.log("Error: ", err);
                });
            },
            getDataProjects(investor_id) {
                // Proyectos...
                investorService.getListProjectsInvestor(investor_id).then(record => {
                    if (record.success) {
                        this.projects = record.value;
                        this.selectedProjectId = this.projects[0].project.id;
                        investorService.getReportProjectsInvestor(investor_id).then(res => {
                            if (res.success) {
                                this.dataReportProjectInvestor = res.data;
                                this.cardData = [
                                    {
                                        title: 'Inversión Total',
                                        value: this.dataReportProjectInvestor.investment_amount,
                                        is_currency: true,
                                        rate: 0
                                    },
                                    {
                                        title: 'Ingreso Total',
                                        value: this.dataReportProjectInvestor.total_income,
                                        is_currency: true,
                                        rate: 0
                                    },
                                    {
                                        title: 'Gasto Total',
                                        value: this.dataReportProjectInvestor.total_expense,
                                        is_currency: true,
                                        rate: 0
                                    },
                                    {
                                        title: 'Utilidad Total',
                                        value: this.dataReportProjectInvestor.total_revuene,
                                        is_currency: true,
                                        rate: 0
                                    },
                                    {
                                        title: 'Porcentaje de Utilidad',
                                        value: this.dataReportProjectInvestor.total_perc_utility,
                                        is_currency: false,
                                        rate: 0
                                    }
                                ];
                            } else {
                                this.dataReportProjectInvestor = null;
                            }
                        });
                        this.getDetailReport(this.selectedProjectId, investor_id);
                    } else {
                        this.selectedProject = null;
                    }
                }).catch(err => {
                    console.log("Error: ", err);
                }).finally(() =>{
                    // Nada que hacer aquí.
                });
            },
            getDataEvents(investor_id) {
                // Eventos
                investorService.getReportDetailEventsInvestor(investor_id).then(res => {
                    if (res.success) {
                        this.dataReportEventInvestor = res.value;
                        this.cardDataEvent = [
                            {
                                title: 'Inversión Total',
                                value: this.dataReportEventInvestor.total_investment,
                                rate: [Math.floor(Math.random() * (100 - 10 + 1) + 10)]
                            },
                            {
                                title: 'Ingreso Total',
                                value: this.dataReportEventInvestor.total_income,
                                rate: [Math.floor(Math.random() * (100 - 10 + 1) + 10)]
                            },
                            {
                                title: 'Gasto Total',
                                value: this.dataReportEventInvestor.total_expense,
                                rate: [Math.floor(Math.random() * (100 - 10 + 1) + 10)]
                            },
                            {
                                title: 'Ganancia Totale',
                                value: this.dataReportEventInvestor.total_utility,
                                rate: [Math.floor(Math.random() * (100 - 10 + 1) + 10)]
                            }
                        ];
                        this.events = this.dataReportEventInvestor.events;
                        this.chartEventOptions.title.text = 'Eventos';
                        this.seriesEvent[0].data = [];
                        this.seriesEvent[1].data = [];
                        this.chartEventOptions.labels = [];
                        let growValues = [];
                        let revueneValues = [];
                        let expenseValues = [];
                        /* let revueneData = []; */
                        let labels = [];
                        this.events.forEach(data => {
                            let growValue = {
                                x : data.event_name,                  // event_index_name,
                                y : data.investment_income_event,
                                full_label: data.event_name,
                                income: data.investment_income_event,
                                expense: data.expense_event,
                                utility: data.utility_event,
                            };
                            growValues.push(growValue);
                            let expenseValue = {
                                x : data.event_name,        // event_index_name,
                                y : data.expense_event,
                                full_label: data.event_name
                            }
                            expenseValues.push(expenseValue);
                            let revenueValue = {
                                x : data.event_name,        // event_index_name,
                                y : data.utility_event,
                                full_label: data.event_name
                            };
                            revueneValues.push(revenueValue);
                            labels.push(data.event_name);
                        });
                        this.seriesEvent = [
                            {
                                name: 'Ingreso Capital',
                                type: 'column',
                                data: growValues
                            },{
                                name: 'Utilidad o Pérdida',
                                type: 'line',
                                data: revueneValues
                            }
                        ]
                        this.chartEventOptions.xaxis.categories = labels;
                        try{
                          this.$refs.chartEvents.refresh();
                        }catch (e){
                          console.log("success");
                        }
                    } else {
                        this.dataReportEventInvestor = null;
                    }
                });
            }
        },
        mounted() {
            this.eventsProject = [];
            let sessionInvestor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            if (sessionInvestor !== null) {
                let investor_id = sessionInvestor.user.investors[0].id;
                this.selectedInvestorId = investor_id;
                this.getDataProjects(investor_id);
                this.getDataEvents(investor_id);
            }
            setTimeout(() => (this.elementVisible = true), 10);
        }
    }
</script>

<style scoped>
    .simple-border {
        border: thin solid #e1e0e0;
        border-radius: 4px;
    }
    .font-raleway {
        font-family: "Raleway", sans-serif !important;
    }
    .font-lato {
        font-family: "Lato", sans-serif !important;
    }
    @media (min-width: 1264px) and (max-width: 1903px) {
        .flex.lg5-custom {
            width: 20%;
            max-width: 20%;
            flex-basis: 20%;
        }
    }
    .v-expansion-panels.condensed .v-expansion-panel-header {
        padding-top: 2px;
        padding-bottom: 2px;
        min-height: 50px;
    }
    .v-expansion-panels.condensed
    .v-expansion-panel--active
    .v-expansion-panel-header {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .v-expansion-panels.condensed .v-expansion-panel--active:not(:first-child),
    .v-expansion-panels.condensed .v-expansion-panel--active + .v-expansion-panel {
        margin-top: 0;
    }
    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
        padding: 10px !important;
    }
</style>

<!--
https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900?page=1&tab=scoredesc#tab-top

function kFormatter(num) {
return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

console.log(kFormatter(1200)); // 1.2k
console.log(kFormatter(-1200)); // -1.2k
console.log(kFormatter(900)); // 900
console.log(kFormatter(-900)); // -900

var num = 54321;

// To format the number so it has commas in the correct position,
// and to express it in thousands:
var ans = num.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 0, minimumFractionDigits: 0, useGrouping: true });
console.log("Formatted to 'K': "+ ans); // 54,321


let formatter = Intl.NumberFormat('en', { notation: 'compact' });
// example 1
let million = formatter.format(1e6);
// example 2
let billion = formatter.format(1e9);
// print
console.log(million == '1M', billion == '1B');

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast(item => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

/*
 * Tests
 */
const tests = [
  { num: 0, digits: 1 },
  { num: 12, digits: 1 },
  { num: 1234, digits: 1 },
  { num: 100000000, digits: 1 },
  { num: 299792458, digits: 1 },
  { num: 759878, digits: 1 },
  { num: 759878, digits: 0 },
  { num: 123, digits: 1 },
  { num: 123.456, digits: 1 },
  { num: 123.456, digits: 2 },
  { num: 123.456, digits: 4 }
];
tests.forEach(test => {
  console.log("nFormatter(%f, %i) = %s", test.num, test.digits, nFormatter(test.num, test.digits));
});

const formatCash = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

nFormatter(num) {
                if (num >= 1000000000) {
                    return (num / 1000000000).toFixed(1).replace(/\.0$/, '');// + 'G';
                }
                if (num >= 1000000) {
                    return (num / 1000000).toFixed(1).replace(/\.0$/, '');// + 'M';
                }
                if (num >= 1000) {
                    return (num / 1000).toFixed(1).replace(/\.0$/, '');// + 'K';
                }
                return num;
            },
            nSymbol(num) {
                if (num >= 1000000000) {
                    return 'G';
                }
                if (num >= 1000000) {
                    return 'M';
                }
                if (num >= 1000) {
                    return 'K';
                }
                return num;
            },
            xFormatter(number) {
                // const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
                // what tier? (determines SI symbol)
                let tier = Math.log10(Math.abs(number)) / 3 | 0;

                // if zero, we don't need a suffix
                if(tier === 0) return number;

                // get suffix and determine scale
                // let suffix = SI_SYMBOL[tier];
                let scale = Math.pow(10, tier * 3);

                // scale the number
                let scaled = number / scale;

                // format number and add suffix
                console.log('Value: ', scaled.toFixed(1));
                return scaled.toFixed(1); // + suffix;
            },
            getSymbol(number) {
                const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
                // what tier? (determines SI symbol)
                let tier = Math.log10(Math.abs(number)) / 3 | 0;

                // if zero, we don't need a suffix
                if(tier === 0) return number;

                // get suffix and determine scale
                let suffix = SI_SYMBOL[tier];
                // let scale = Math.pow(10, tier * 3);

                // scale the number
                // let scaled = number / scale;

                // format number and add suffix
                console.log('suffix: ', suffix);
                return suffix;
            },
            shortenLargeNumber(num, digits) {
                let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
                    decimal;

                for(let i=units.length-1; i>=0; i--) {
                    decimal = Math.pow(1000, i+1);

                    if(num <= -decimal || num >= decimal) {
                        return +(num / decimal).toFixed(digits) + units[i];
                    }
                }
                return num;
            },
            formatToUnits(number, precision) {
                const abbrev = ['', 'k', 'm', 'b', 't'];
                const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
                const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
                const suffix = abbrev[order];

                return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
            }

-->
