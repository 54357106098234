<template>
    <investor-dashboard-component/>
</template>

<script>
    import InvestorDashboardComponent from "@/components/investors/InvestorDashboardComponent";

    export default {
        name: "InvestorDashboardView",
        components: {InvestorDashboardComponent}
    }
</script>

<style scoped>

</style>